import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { IGenericListParameters } from 'app/components/generic/list/list.interface';
import { LoadingService } from 'app/services/loading.service';
import { TitleService } from 'app/services/title.service';
import { openGenericDeleteDialog } from 'src/app/components/generic/generic-dialog/generic-delete.dialog';
import { GenericDialogComponent } from 'src/app/components/generic/generic-dialog/generic-dialog.component';
import {
  generateEditButton,
  generateRemoveButton,
  ListActionEvent
} from 'src/app/components/generic/list/list.component';
import { Profile, ProfileService } from 'src/app/services/profile.service';
import { environment } from 'src/environments/environment';

@Component({
  templateUrl: './profiles.list.component.html'
})
export class ProfilesListComponent implements OnInit {
  public items: any[] = [];
  public total = 0;
  public loaded = false;
  public isOff = environment.USE_MOCKED_PROFILE;

  public listParameters: IGenericListParameters = {
    paginator: true,
    clickable: false,
    listHead: [
      {
        key: 'code',
        type: 'text',
        title: 'PROFILES.COMPANY_CODE',
        color: 'main-color-light',
        styles: { fontWeight: 'bold' }
      },
      {
        key: 'shortname',
        type: 'text',
        title: 'PROFILES.SHORTNAME'
      },
      {
        key: 'name',
        type: 'text',
        title: 'PROFILES.NAME'
      },
      {
        key: 'mainLogo',
        type: 'imageSrc',
        title: 'PROFILES.MAIN'
      },
      {
        key: 'secondaryLogo',
        type: 'imageSrc',
        title: 'PROFILES.SECONDARY'
      },
      {
        key: 'actions',
        type: 'button'
      }
    ]
  };

  constructor(
    public loading: LoadingService,
    private translate: TranslateService,
    private title: TitleService,
    public dialog: MatDialog,
    private profileService: ProfileService
  ) {
    this.title.setTitle('GLOBAL.BREADCRUMB.PROFILES.LIST');
  }

  async ngOnInit() {
    this.fetchData();
  }

  private fetchData(): void {
    this.loaded = false;
    this.loading.on();
    this.profileService.getAll().then((profiles) => {
      this.loading.off();
      this.items = this.parseData(profiles);
      this.total = profiles.length;
      this.loaded = true;
    });
  }

  private parseData(items: any[]): any[] {
    return items.map((e) => {
      return {
        ...e,
        actions: [generateEditButton, generateRemoveButton],
        original: e
      };
    });
  }

  private async deleteOne(profileId: string) {
    this.profileService.deleteOne({ id: profileId }).then(() => this.fetchData());
  }

  public handleRefresh(): void {
    this.fetchData();
  }

  public handleAction(event: ListActionEvent): void {
    const { type } = event;
    if (!type) {
      return;
    }
    switch (type) {
      case 'addButtonClick':
        this.dialog.open(GenericDialogComponent, {
          width: '40%',
          data: this.addModalStructure(),
          disableClose: true
        });
        break;
      case 'edit':
        this.dialog.open(GenericDialogComponent, {
          width: '40%',
          data: this.editModalStructure(event.data.id, event.data),
          disableClose: true
        });
        break;
      case 'delete':
        openGenericDeleteDialog(this.dialog, 'CONFIRM.DELETE_PROFILE', () => this.deleteOne(event.data.id));
        break;
    }
  }

  private addModalStructure() {
    return {
      structure: {
        title: 'GLOBAL.BREADCRUMB.PROFILES.NEW',
        text: 'GLOBAL.BREADCRUMB.PROFILES.NEW'
      },
      content: {
        endpoint: `api://profiles`,
        method: 'post',
        treatValuesBeforeSubmit: (values: any) => {
          const obj = { ...JSON.parse(values.json) };
          return obj;
        },
        onSuccess: () => this.fetchData(),
        doNotReloadOnSuccess: true,
        type: 'form',
        fields: [
          {
            key: 'json',
            title: 'JSON',
            value: '',
            type: 'textarea',
            cdkAutosizeMaxRows: '12'
          }
        ],
        button: {
          text: this.translate.instant('GLOBAL.SUBMIT_BUTTON_LABEL'),
          class: 'validate'
        }
      }
    };
  }

  private editModalStructure(id: string, data: any) {
    const initialValues = data.original as Partial<Profile>;
    return {
      structure: {
        title: 'GLOBAL.BREADCRUMB.PROFILES.EDIT',
        text: 'GLOBAL.BREADCRUMB.PROFILES.EDIT'
      },
      content: {
        endpoint: `api://profiles/${id}`,
        method: 'patch',
        treatValuesBeforeSubmit: (values: any) => {
          const obj = { ...JSON.parse(values.json) };
          return obj;
        },
        onSuccess: () => {
          // In case of a current profile change it seems we can only properly setup the profile by reloading the app
          window.location.reload();
          this.fetchData();
        },
        doNotReloadOnSuccess: true,
        type: 'form',
        fields: [
          {
            key: 'json',
            title: 'JSON',
            value: JSON.stringify(initialValues, null, 2),
            type: 'textarea',
            cdkAutosizeMaxRows: '12'
          }
        ],
        button: {
          text: this.translate.instant('GLOBAL.SUBMIT_BUTTON_LABEL'),
          class: 'validate'
        },
        disableClose: true
      }
    };
  }
}
