export const environment = {
  production: true,
  APP_TITLE: "Vigie Réseau",
  GOOGLE_MAP_API_KEY: "AIzaSyD0EN86qQuYRoBP1S6XmwIXEg0eetz_1RA",
  GIT_COMMIT: "7732f4c7",
  BASE_URL: "https://vigie-reseau-preprod.data-newroad.com",
  USE_MOCKED_PROFILE: false,
  MOCKED_PROFILE: {
    API_URL: "http://localhost:8985/api/1.0",
    KEYCLOAK_REALM: "datanewroad_vigiereseau_uat",
  },
  PROFILE_API_URL:
    "https://profiles.vigie-reseau-preprod.data-newroad.com",
  keycloak: {
    redirectUrl: "https://vigie-reseau-preprod.data-newroad.com",
    postLogoutRedirectUri:
      "https://vigie-reseau-preprod.data-newroad.com",
    stsServer: "https://keycloak.data-newroad.com",
    clientId: "frontend",
    wellknownEndpoint:
      "https://keycloak.data-newroad.com",
  },
};
