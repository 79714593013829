import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import _ from 'lodash';
import { BehaviorSubject, filter, lastValueFrom, map, Observable } from 'rxjs';
import { getRootCSSVariable, getRootCSSVariableNames, setRootCSSVariable } from 'src/app/tools/css-variable.helper';
import { environment } from 'src/environments/environment';

type ProfileCSSVariables = Record<string, string | number | undefined | null>;

export interface Profile {
  code: string; // Client profile ID. A same account name can have many profile.
  name: string; // Account's name
  shortname: string; // Account's short name
  description?: string; // Description of the company. Could be usefull later
  realm: string; // Realm name for each client
  api: string; // Base API URL
  palette: ProfileCSSVariables; // API URL to get the scss variables stylesheet
  mainLogo?: string;
  secondaryLogo?: string;
  activeModules: ActiveModules;
}

export interface ActiveModules {
  instructions: boolean;
  tasks: boolean;
  controls: boolean;
}

@Injectable()
export class ProfileService {
  constructor(private http: HttpClient) {}

  private static profileSubject = new BehaviorSubject<Profile | null>(null);
  private static resolveValidProfile: (profile: Profile) => void;
  private static validProfilePromise = new Promise<Profile>((s) => (ProfileService.resolveValidProfile = s));
  private static initialCSSVariables: ProfileCSSVariables;

  public profile = ProfileService.profileSubject;
  public profileCode = ProfileService.profileSubject.pipe(map((e) => e?.code || null));

  public async init(): Promise<boolean /* isProfile */> {
    ProfileService.storeInitialCSSVariables();
    const profileCode = localStorage.getItem('profileCode');
    if (!profileCode) {
      localStorage.removeItem('profileCode');
      return false;
    }
    const profile = await this.getOneOrClear(profileCode);
    ProfileService.setProfile(profile);
    return true;
  }

  public static getValidProfilePromise(): Promise<Profile> {
    return ProfileService.validProfilePromise;
  }

  public static getValidProfileObservable(): Observable<Profile> {
    return this.profileSubject.pipe(filter<any>((e) => e !== null));
  }

  //
  // Remote CRUD
  //

  public async getOneOrClear(profileCode: string): Promise<Profile> {
    // If the environment is configure not to have a PROFILE_API_URL, we fallback on a mocked profile
    // The main use of this is to develop the Backoffice/API without having to run the profile service locally
    if (environment.USE_MOCKED_PROFILE) {
      return import('./profile-mock.service').then((e) => e.getDevProfile());
    }
    try {
      return await lastValueFrom(this.http.get<Profile>(`profile-api://profiles/${profileCode}`));
    } catch (err) {
      ProfileService.clearProfile();
      throw err;
    }
  }

  /**
   * Only admin is authorized to getAll
   */
  public async getAll(): Promise<Profile[]> {
    return lastValueFrom(this.http.get<Profile[]>(`api://profiles`));
  }

  /**
   * Only admin is authorized to deleteOne
   */
  public async deleteOne(options: { id: string }): Promise<void> {
    const { id } = options || {};
    await lastValueFrom(this.http.delete(`api://profiles/${id}`));
  }

  public async sendProfileCode(profileCode: string) {
    const profile = await this.getOneOrClear(profileCode);
    ProfileService.setProfile(profile);
  }

  public handleClearProfileClick(): void {
    ProfileService.clearProfile();
  }

  //
  // Current profile
  //

  public static clearProfile() {
    localStorage.removeItem('profileCode');
    // ProfileService.profileSubject.next(null);
    // this.restoreInitialCSSVariables();
    window.sessionStorage.clear();
    window.location.reload();
  }

  private static setProfile(profile: Profile) {
    localStorage.setItem('profileCode', profile.code);
    localStorage.setItem('profileMainLogo', profile.mainLogo || '');
    ProfileService.profileSubject.next(profile);
    this.applyProfileCSSVariables(profile.palette as unknown as ProfileCSSVariables);
    ProfileService.resolveValidProfile(profile);
  }

  public static getCurrentProfile() {
    return ProfileService.profileSubject.getValue();
  }

  public static getAPIUrl() {
    const profile = ProfileService.profileSubject.getValue();
    if (!profile) {
      throw Error('getAPIUrl: no profile data yet');
    }
    return profile.api;
  }

  //
  // CSS variables
  //

  private static applyProfileCSSVariables(cssVariables: ProfileCSSVariables) {
    if (!cssVariables) return;
    Object.entries(cssVariables).forEach(([key, value]) => {
      if (value && !_.isString(value) && !_.isNumber(value)) {
        value = null;
      }
      setRootCSSVariable('--' + key, value);
    });
  }

  private static storeInitialCSSVariables() {
    const variableNames = getRootCSSVariableNames();
    const cssVariables: ProfileCSSVariables = {};
    variableNames.forEach((key) => (cssVariables[key.slice(2)] = getRootCSSVariable(key)));
    ProfileService.initialCSSVariables = cssVariables;
  }

  /**
   * Unused since we cannot change profile at runtime ('angular-auth-oidc-client' does not support it)
   */
  public static restoreInitialCSSVariables() {
    this.applyProfileCSSVariables(ProfileService.initialCSSVariables);
  }
}

// temp dev tools
if (localStorage.getItem('devMode')) {
  (window as any).ProfileService = ProfileService;
}
